import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const LayoutContext = createContext({
  backgroundImage: '/images/background.webp',
  navLink: { name: null, href: '#' }
});

const LayoutProvider = ({ children }) => {
  const [backgroundImage, setBackgroundImage] = useState('/images/background.webp');
  const [sideBarLink, setSideBarLink] = useState({ name: null, href: '#' });
  const { i18n } = useTranslation();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname) return;
    if (['/contacts', '/about', '/'].includes(pathname)) {
      setBackgroundImage(`/images/background.webp`);
      setSideBarLink({
        name: i18n.language === 'ru' ? 'Главная' : 'Home',
        href: '#'
      });
    }

    if (['/privacy', '/404'].includes(pathname)) {
      setBackgroundImage(`/images/background.webp`);
      setSideBarLink({ name: null, href: '#' });
    }
  }, [i18n.language, pathname]);

  return (
    <LayoutContext.Provider
      value={{
        backgroundImage,
        setBackgroundImage,
        sideBarLink,
        setSideBarLink
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export { LayoutProvider, LayoutContext };
