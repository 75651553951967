// Material UI
import { Box, Typography, Link } from '@mui/material';

// Localization
import { useTranslation } from 'react-i18next';

// React Router Navigation
import { Link as RouterLink } from 'react-router-dom';

// Component Imports
import Loader from './Loader';
import Image from './Image';

// Data Imports
import { getRandomArtwork, getArtworkLocalLocation } from '../data/artworks';

export default function RandomArtwork() {
  const { i18n, t } = useTranslation();
  const LinkRouter = (props) => <Link component={RouterLink} {...props} />;

  const randomWork = getRandomArtwork();
  const artoworkLink = `/artwork/${randomWork.slug}`;
  const artworkSrc = getArtworkLocalLocation(randomWork.id, randomWork.slug);
  const artworkTitle =
    i18n.language === 'ru'
      ? `${randomWork.titleRu}, ${randomWork.year}`
      : `${randomWork.titleEn}, ${randomWork.year}`;

  return !randomWork ? (
    <Loader />
  ) : (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', padding: 10 }}>
      <Typography align='center'>{t('randomWork.title')}</Typography>

      <LinkRouter to={artoworkLink} underline='none'>
        <Image src={artworkSrc} title={artworkTitle} alt={artworkTitle} sx={{ my: 7 }} />
      </LinkRouter>
      <Typography variant='h6' component='h2' align='center'>
        {i18n.language === 'ru' ? randomWork.titleRu : randomWork.titleEn}
      </Typography>
      <LinkRouter to={artoworkLink} underline='none' color='text.secondary' align='center'>
        {t('randomWork.details')}
      </LinkRouter>
    </Box>
  );
}
