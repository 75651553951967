import React from 'react';

// React Router Navigation
import { Navigate, createBrowserRouter } from 'react-router-dom';

// Layout Imports
import MainLayout from './layouts/Main';

// Main Page Imports
const About = React.lazy(() => import('./pages/About'));
const Contacts = React.lazy(() => import('./pages/Contacts'));
const ArtworkList = React.lazy(() => import('./pages/ArtworkList'));
const ArtworkDetails = React.lazy(() => import('./pages/ArtworkDetails'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Order = React.lazy(() => import('./pages/Order'));

import { LayoutProvider } from './LayoutContext';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <LayoutProvider>
        <MainLayout />
      </LayoutProvider>
    ),
    children: [
      { path: '', element: <Navigate to='/about' replace /> },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'contacts',
        element: <Contacts />
      },
      {
        path: 'order',
        element: <Order />
      },

      {
        path: 'collection',
        children: [
          {
            path: '',
            element: <ArtworkList />
          },
          {
            path: ':seriesSlug',
            element: <ArtworkList />
          }
        ]
      },

      {
        path: 'artwork',
        children: [
          {
            path: '',
            element: <Navigate to='/collection' replace />
          },
          {
            path: ':artworkSlug',
            element: <ArtworkDetails />
          }
        ]
      },

      {
        path: '/privacy',
        element: <Privacy />
      },
      {
        path: '/404',
        element: <NotFound />
      }
    ]
  },

  { path: '*', element: <Navigate to='404' replace /> }
]);
