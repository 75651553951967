import React from 'react';

// Material UI
import { createTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/private-theming';

// React Router Navigation
import { routes } from './routes';
import { RouterProvider } from 'react-router-dom';

// Components
import Loader from './components/Loader';

const App = () => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={routes} fallbackElement={Loader} />
      <CssBaseline />
    </ThemeProvider>
  );
};

export default App;
