import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// React Helmet
import { HelmetProvider } from 'react-helmet-async';

// Project imports
import App from './App';
import Loader from './components/Loader';

// Localization
import './i18n';

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Suspense>,

  document.getElementById('root')
);
