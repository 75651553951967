import { useCallback, useContext, useState } from 'react';

// Material UI
import { Box, IconButton, Typography, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Components
import RandomArtwork from '../../components/RandomArtwork';
import Copyright from '../../components/Copyright';
import HeaderSidebar from './HeaderSidebar';

// Context
import { LayoutContext } from '../../LayoutContext';

// Router Navigation
import { Link as RouterLink } from 'react-router-dom';

const useHandleDrawer = (initialState) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const handleDrawer = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  return [isOpen, handleDrawer];
};

const LinkRouter = (props) => <Link component={RouterLink} {...props} />;

export default function MainRightBar({ changeLanguage, t }) {
  const { backgroundImage } = useContext(LayoutContext);
  const [isOpen, handleDrawer] = useHandleDrawer(false);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          height: { xs: '30vh', md: '100vh' },
          backgroundSize: 'cover',
          backgroundPosition: { xs: '50% 35%', md: '30% 100%' }
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          paddingTop={5}
          width={{ xs: '90%', md: '85%' }}
          mx='auto'
        >
          <LinkRouter
            to={`/`}
            underline='none'
            sx={{
              textTransform: 'uppercase',
              width: 'fit-content',
              color: { xs: 'white', md: 'black' },
              display: { xs: 'block', md: 'none' }
            }}
          >
            {t('header.title')}
          </LinkRouter>
          <Box display='flex' alignItems='center' justifyContent='flex-end' width='100%'>
            <Typography
              component='span'
              onClick={() => changeLanguage()}
              sx={{
                mr: 2,
                color: 'white',
                textTransform: 'uppercase',
                cursor: 'pointer',
                display: { xs: 'none', sm: 'block' }
              }}
            >
              {t('languageChange')}
            </Typography>
            <Typography
              component='span'
              onClick={handleDrawer}
              sx={{
                mr: 2,
                cursor: 'pointer',
                color: 'white',
                display: { xs: 'none', sm: 'block' }
              }}
            >
              {t('header.menu')}
            </Typography>
            <IconButton
              sx={{ color: 'white' }}
              onClick={handleDrawer}
              size='large'
              edge='start'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        <HeaderSidebar
          changeLanguage={changeLanguage}
          t={t}
          handleDrawer={handleDrawer}
          open={isOpen}
        />
      </Box>
      <RandomArtwork />
      <Copyright sx={{ display: { xs: 'none', md: 'block' } }} />
    </>
  );
}
