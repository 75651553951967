import { useContext } from 'react';

// Material UI
import { Container, Link } from '@mui/material';

// Router Navigation
import { Link as RouterLink } from 'react-router-dom';

// Context
import { LayoutContext } from '../../LayoutContext';

export default function MainLeftBar() {
  const LinkRouter = (props) => <Link component={RouterLink} {...props} />;
  const { sideBarLink: link } = useContext(LayoutContext);

  return (
    <Container sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
      <LinkRouter
        to={link.href}
        underline='none'
        sx={{
          mt: { xs: 12, md: 42 },
          textTransform: 'uppercase',
          transform: 'rotate(180deg)',
          writingMode: 'vertical-lr',
          color: 'black',
          position: 'sticky'
        }}
      >
        {link.name}
      </LinkRouter>
    </Container>
  );
}
