export function getArtworks() {
  return artworks;
}

export function findArtworkBySlug(slug) {
  return artworks.findIndex((artwork) => artwork.slug === slug);
}

export function artworkExists(slug) {
  let artworkLoc = findArtworkBySlug(slug);
  if (artworkLoc === -1) {
    return false;
  }
  return true;
}

export function getArtworkBySlug(slug) {
  let artworkLoc = findArtworkBySlug(slug);
  if (artworkLoc === -1) {
    return null;
  }
  return artworks[artworkLoc];
}

export function getRandomArtwork() {
  return artworks[Math.floor(Math.random() * artworks.length)];
}

export function getArtworkLocalLocation(id, slug) {
  return `/images/artworks/${id}-${slug}.webp`;
}

const artworks = [
  {
    id: 20,
    titleRu: 'Розы',
    titleEn: 'Roses',
    slug: 'roses',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 80,
    year: 2017,
    price: 35000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, розовый',
    seriesId: 9
  },
  {
    id: 21,
    titleRu: 'Гроздья винограда',
    titleEn: 'Bunches of Grapes',
    slug: 'bunches-of-grapes',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 40,
    year: 2018,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, фиолетовый',
    seriesId: 9
  },
  {
    id: 22,
    titleRu: 'Натюрморт тыквы',
    titleEn: 'Still Life of Pumpkin',
    slug: 'still-life-of-pumpkin',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 80,
    year: 2020,
    price: 40000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, коричневый',
    seriesId: 9
  },
  {
    id: 23,
    titleRu: 'Букет в фокусе',
    titleEn: 'Bouquet in Focus',
    slug: 'bouquet-in-focus',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 60,
    year: 2017,
    price: 25000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, зеленый',
    seriesId: 9
  },
  {
    id: 24,
    titleRu: 'Сирень',
    titleEn: 'Lilac',
    slug: 'lilac',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 65,
    year: 2015,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый',
    seriesId: 9
  },
  {
    id: 25,
    titleRu: 'Цитрусы',
    titleEn: 'Citruses',
    slug: 'citruses',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 40,
    year: 2017,
    price: 35000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, белый',
    seriesId: 9
  },
  {
    id: 26,
    titleRu: 'Букет',
    titleEn: 'Bouquet',
    slug: 'bouquet',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 70,
    year: 2017,
    price: 25000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый',
    seriesId: 9
  },
  {
    id: 28,
    titleRu: 'Чертополох',
    titleEn: 'Thistle',
    slug: 'thistle',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 40,
    year: 2015,
    price: 30000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, белый',
    seriesId: 9
  },
  {
    id: 30,
    titleRu: 'Свет прошлых лет',
    titleEn: 'Light of the Past',
    slug: 'light-of-the-past',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 100,
    year: 2015,
    price: 250000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, оранжевый, желтый',
    seriesId: 10
  },
  {
    id: 31,
    titleRu: 'Сияние света',
    titleEn: 'Shining light',
    slug: 'shining-light',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 60,
    year: 2020,
    price: 200000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, оранжевый, желтый',
    seriesId: 10
  },
  {
    id: 32,
    titleRu: 'Тюльпаны',
    titleEn: 'Tulips',
    slug: 'tulips',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 40,
    year: 2015,
    price: 25000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный',
    seriesId: 9
  },
  {
    id: 33,
    titleRu: 'Буйство красок',
    titleEn: 'Riot of colors',
    slug: 'riot-of-colors',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 35,
    width: 45,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, фиолетовый розовый',
    seriesId: 9
  },
  {
    id: 34,
    titleRu: 'Импровизация',
    titleEn: 'Improvisation',
    slug: 'improvisation',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 80,
    year: 2012,
    price: 80000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, оранжевый',
    seriesId: 7
  },
  {
    id: 35,
    titleRu: 'Волна',
    titleEn: 'Wave',
    slug: 'wave',
    materialRu: 'Планшет, акрил',
    materialEn: 'Acrylic on tablet',
    height: 40,
    width: 60,
    year: 2019,
    price: 10000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Синий, голубой',
    seriesId: 10
  },
  {
    id: 36,
    titleRu: 'Старая башня',
    titleEn: 'Old Tower',
    slug: 'old-tower',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 17,
    year: 2015,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, коричневый',
    seriesId: 10
  },
  {
    id: 37,
    titleRu: 'Подсолнухи',
    titleEn: 'Sunflowers',
    slug: 'sunflowers',
    materialRu: 'Бумага, карандаш',
    materialEn: 'Pencil on paper',
    height: 93,
    width: 62,
    year: 2015,
    price: 350000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Черно-белый',
    seriesId: 9
  },
  {
    id: 38,
    titleRu: 'Мираж',
    titleEn: 'Mirage',
    slug: 'mirage',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 90,
    width: 100,
    year: 2014,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, желтый, коричневый, фиолетовый',
    seriesId: 7
  },
  {
    id: 39,
    titleRu: 'Ирисы на розовом фоне',
    titleEn: 'Irises on a Pink Background',
    slug: 'irises-on-a-pink-background',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 80,
    year: 2017,
    price: 20000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Розовый, синий',
    seriesId: 9
  },
  {
    id: 40,
    titleRu: 'Алые розы',
    titleEn: 'Scarlet Roses',
    slug: 'scarlet-roses',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 59,
    width: 59,
    year: 2012,
    price: 35000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный',
    seriesId: 9
  },
  {
    id: 41,
    titleRu: 'Груша на ткани',
    titleEn: 'Pear on Fabric',
    slug: 'pear-on-fabric',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 40,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, белый',
    seriesId: 9
  },
  {
    id: 42,
    titleRu: 'Ирисы – Цветы любви',
    titleEn: 'Irises - Flowers of Love',
    slug: 'irises-flowers-of-love',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 90,
    width: 100,
    year: 2012,
    price: 150000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, фиолетовый',
    seriesId: 11
  },
  {
    id: 43,
    titleRu: 'Карибская красавица',
    titleEn: 'Caribbean Beauty',
    slug: 'caribbean-beauty',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 120,
    year: 2012,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, коричневый, жлтый',
    seriesId: 9
  },
  {
    id: 44,
    titleRu: 'Ирисы серебро',
    titleEn: 'Irises Silver',
    slug: 'irises-silver',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 90,
    width: 100,
    year: 2012,
    price: 50000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, голубой, синий',
    seriesId: 9
  },
  {
    id: 46,
    titleRu: 'Казба',
    titleEn: 'Kazba',
    slug: 'kazba',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2020,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, желтый, коричневый, оранжевый',
    seriesId: 10
  },
  {
    id: 47,
    titleRu: 'Абхазский натюрморт',
    titleEn: 'Abkhazian Still Life',
    slug: 'abkhazian-still-life',
    materialRu: 'Бумага, карандаш',
    materialEn: 'Pencil on paper',
    height: 80,
    width: 100,
    year: 2012,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Черно-белый',
    seriesId: 9
  },
  {
    id: 48,
    titleRu: 'Кубанский натюрморт',
    titleEn: 'Kuban Still Life',
    slug: 'kuban-still-life',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 100,
    year: 2012,
    price: 280000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Черно-белый',
    seriesId: 9
  },
  {
    id: 49,
    titleRu: 'Ихтиос',
    titleEn: 'Ichthys',
    slug: 'ichthys',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 100,
    year: 2012,
    price: 150000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричевый',
    seriesId: 9
  },
  {
    id: 50,
    titleRu: 'Селена',
    titleEn: 'Selena',
    slug: 'selena',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 95,
    width: 120,
    year: 2012,
    price: 230000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, оранжевый',
    seriesId: 9
  },
  {
    id: 51,
    titleRu: 'Вечер',
    titleEn: 'Evening',
    slug: 'evening',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 70,
    year: 2015,
    price: 80000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, оранжевый, желтый',
    seriesId: 10
  },
  {
    id: 52,
    titleRu: 'Плоды осени',
    titleEn: 'Autumn Fruits',
    slug: 'autumn-fruits',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 120,
    width: 160,
    year: 2017,
    price: 80000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, коричневый',
    seriesId: 9
  },
  {
    id: 53,
    titleRu: 'Ритмы',
    titleEn: 'Rhythms',
    slug: 'rhythms',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 100,
    year: 2015,
    price: 300000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, коричневый',
    seriesId: 10
  },
  {
    id: 54,
    titleRu: 'Солнечное настроение',
    titleEn: 'Sunny Mood',
    slug: 'sunny-mood',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 80,
    year: 2016,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, зеленый',
    seriesId: 9
  },
  {
    id: 55,
    titleRu: 'Helianhus №1',
    titleEn: 'Helianhus  №1',
    slug: 'helianhus-1',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 120,
    year: 2016,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, коричневый, оранжевый',
    seriesId: 10
  },
  {
    id: 56,
    titleRu: 'Helianhus №2',
    titleEn: 'Helianhus  №2',
    slug: 'helianhus-2',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 120,
    year: 2016,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, коричневый, оранжевый',
    seriesId: 10
  },
  {
    id: 57,
    titleRu: 'Копченая рыба',
    titleEn: 'Smoked Fish',
    slug: 'smoked-fish',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 100,
    year: 2015,
    price: 70000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, зеленый, желтый',
    seriesId: 9
  },
  {
    id: 58,
    titleRu: 'Знак рыбы',
    titleEn: 'Pisces Sign',
    slug: 'pisces-sign',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 160,
    width: 120,
    year: 2015,
    price: 250000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зелены, коричневый',
    seriesId: 9
  },
  {
    id: 59,
    titleRu: 'Голова толстолобика',
    titleEn: 'Silver Carp Head',
    slug: 'silver-carp-head',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 100,
    year: 2015,
    price: 90000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, желтый',
    seriesId: 9
  },
  {
    id: 60,
    titleRu: 'Врата',
    titleEn: 'Gates',
    slug: 'gates',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 100,
    year: 2015,
    price: 60000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, черный',
    seriesId: 10
  },
  {
    id: 61,
    titleRu: 'Осень над хутором',
    titleEn: 'Autumn over the Farm',
    slug: 'autumn-over-the-farm',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 80,
    year: 2012,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, коричневый',
    seriesId: 9
  },
  {
    id: 62,
    titleRu: 'Вечерний город',
    titleEn: 'Evening Town',
    slug: 'evening-town',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 60,
    year: 2014,
    price: 40000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый',
    seriesId: 10
  },
  {
    id: 63,
    titleRu: 'Золотая рыба',
    titleEn: 'Golden Fish',
    slug: 'golden-fish',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 60,
    year: 2016,
    price: 35000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Орнжевый, коричневый',
    seriesId: 9
  },
  {
    id: 64,
    titleRu: 'Тайны океана',
    titleEn: 'Ocean Secrets',
    slug: 'ocean-secrets',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 80,
    year: 2012,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, коричневый, черный',
    seriesId: 9
  },
  {
    id: 65,
    titleRu: 'Арка',
    titleEn: 'Arch',
    slug: 'arch',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 80,
    year: 2015,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, коричневый',
    seriesId: 10
  },
  {
    id: 66,
    titleRu: 'Старый Екатеринодар',
    titleEn: 'Old Town Ekaterinodar',
    slug: 'old-town-ekaterinodar',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 200,
    year: 2015,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, коричневый',
    seriesId: 10
  },
  {
    id: 67,
    titleRu: 'Тайны ночного города',
    titleEn: 'Secrets of the Midnight Town',
    slug: 'secrets-of-the-midnight-town',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 100,
    year: 2015,
    price: 400000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, зеленый, коричневый',
    seriesId: 10
  },
  {
    id: 68,
    titleRu: 'Корнеплод',
    titleEn: 'Root Crop',
    slug: 'root-crop',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 15,
    width: 20,
    year: 2018,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Синий, фиолетовый',
    seriesId: 9
  },
  {
    id: 69,
    titleRu: 'Нимфы',
    titleEn: 'Nymphs',
    slug: 'nymphs',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 60,
    year: 2018,
    price: 23000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, зеленый, желтый',
    seriesId: 11
  },
  {
    id: 70,
    titleRu: 'Незнакомка',
    titleEn: 'Stranger Woman',
    slug: 'stranger-woman',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Синий, желтый',
    seriesId: 11
  },
  {
    id: 71,
    titleRu: 'Вечер над хутором',
    titleEn: 'Evening over the Farm',
    slug: 'evening-over-the-farm',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 120,
    width: 160,
    year: 2016,
    price: 350000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, черный',
    seriesId: 9
  },
  {
    id: 72,
    titleRu: 'Кубанский натюрморт (Графика)',
    titleEn: 'Kuban Still Life (Graphic)',
    slug: 'kuban-still-life-graphic',
    materialRu: 'Бумага, карандаш',
    materialEn: 'Pencil on paper',
    height: 80,
    width: 100,
    year: 2012,
    price: 150000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Черно-белый',
    seriesId: 9
  },
  {
    id: 73,
    titleRu: 'Большой цветок',
    titleEn: 'Big Flower',
    slug: 'big-flower',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 80,
    year: 2019,
    price: 25000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Черный, розовый',
    seriesId: 9
  },
  {
    id: 74,
    titleRu: 'Полевой букет',
    titleEn: 'Field Bouquet',
    slug: 'field-bouquet',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 50,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Синий, белый, роозовый',
    seriesId: 9
  },
  {
    id: 75,
    titleRu: 'Екатеринодар',
    titleEn: 'Ekaterinodar',
    slug: 'ekaterinodar',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 50,
    year: 2013,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый ,коричневый',
    seriesId: 10
  },
  {
    id: 76,
    titleRu: 'Прохлада леса',
    titleEn: 'Forest Coolness',
    slug: 'forest-coolness',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 30,
    year: 2019,
    price: 11000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Голубой, зеленый',
    seriesId: 10
  },
  {
    id: 77,
    titleRu: 'Груша',
    titleEn: 'Pear',
    slug: 'pear',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 26,
    year: 2019,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, красный, синий',
    seriesId: 9
  },
  {
    id: 78,
    titleRu: 'Снежные горы',
    titleEn: 'Winter Mountains',
    slug: 'winter-mountains',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2019,
    price: 20000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Белый, коричневый, зленый',
    seriesId: 10
  },
  {
    id: 79,
    titleRu: 'Лимоны',
    titleEn: 'Lemons',
    slug: 'lemons',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 15,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, зеленый, черный',
    seriesId: 9
  },
  {
    id: 80,
    titleRu: 'Гранат',
    titleEn: 'Pomegranate',
    slug: 'pomegranate',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 15,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, коричневый ,черный',
    seriesId: 9
  },
  {
    id: 81,
    titleRu: 'Яблоко',
    titleEn: 'Apple',
    slug: 'apple',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 15,
    year: 2019,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, черный',
    seriesId: 9
  },
  {
    id: 82,
    titleRu: 'Куст Сирени',
    titleEn: 'Lilac Bush',
    slug: 'lilac-bush',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 30,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый',
    seriesId: 9
  },
  {
    id: 83,
    titleRu: 'Березовая роща',
    titleEn: 'Birch Grove',
    slug: 'birch-grove',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 30,
    year: 2019,
    price: 9000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, желтый',
    seriesId: 10
  },
  {
    id: 84,
    titleRu: 'Груши',
    titleEn: 'Pears',
    slug: 'pears',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 27,
    width: 31,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый .зеленый',
    seriesId: 9
  },
  {
    id: 85,
    titleRu: 'Италия',
    titleEn: 'Italy',
    slug: 'italy',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 30,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, голубой',
    seriesId: 10
  },
  {
    id: 86,
    titleRu: 'Венеция',
    titleEn: 'Venice',
    slug: 'venice',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, голубой',
    seriesId: 10
  },
  {
    id: 87,
    titleRu: 'Берег Кубани',
    titleEn: 'Kuban Shore',
    slug: 'kuban-shore',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2019,
    price: 8000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый ,синий, красный',
    seriesId: 10
  },
  {
    id: 88,
    titleRu: 'Озеро',
    titleEn: 'Lake',
    slug: 'lake',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2019,
    price: 5000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, зеленый',
    seriesId: 10
  },
  {
    id: 90,
    titleRu: 'Хризолитовый вечер',
    titleEn: 'Chrysolite Evening',
    slug: 'chrysolite-evening',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 30,
    year: 2015,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'желтый, зеленый',
    seriesId: 10
  },
  {
    id: 91,
    titleRu: 'Букет с калиной',
    titleEn: 'Viburnum Bouquet',
    slug: 'viburnum-bouquet',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 35,
    width: 45,
    year: 2019,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Синий, белый, красный',
    seriesId: 9
  },
  {
    id: 92,
    titleRu: 'Краски весны',
    titleEn: 'Spring colors',
    slug: 'spring-colors',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 70,
    year: 2020,
    price: 23000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, желтый',
    seriesId: null
  },
  {
    id: 93,
    titleRu: 'Весна',
    titleEn: 'Spring',
    slug: 'spring',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 50,
    year: 2020,
    price: 20000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый',
    seriesId: 9
  },
  {
    id: 94,
    titleRu: 'Натюрморт с фруктами',
    titleEn: 'Fruit Still Life',
    slug: 'fruit-still-life',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2013,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 9
  },
  {
    id: 96,
    titleRu: 'Афродита',
    titleEn: 'Aphrodite',
    slug: 'aphrodite',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 50,
    year: 2012,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, синий',
    seriesId: 11
  },
  {
    id: 97,
    titleRu: 'Амазонки',
    titleEn: 'Amazons',
    slug: 'amazons',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 70,
    year: 2011,
    price: 40000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, коричневый',
    seriesId: 11
  },
  {
    id: 98,
    titleRu: 'Первые тюльпаны',
    titleEn: 'First Tulips',
    slug: 'first-tulips',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 50,
    year: 2016,
    price: 5000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный',
    seriesId: 9
  },
  {
    id: 99,
    titleRu: 'Энергия Инь',
    titleEn: 'Yin Energy',
    slug: 'yin-energy',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 50,
    year: 2020,
    price: 35000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, черный',
    seriesId: 9
  },
  {
    id: 100,
    titleRu: 'Таинственный шепот',
    titleEn: 'Mysterious whisper',
    slug: 'mysterious-whisper',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2020,
    price: 20000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый',
    seriesId: 9
  },
  {
    id: 101,
    titleRu: 'Простор',
    titleEn: 'Vast',
    slug: 'vast',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 30,
    year: 2019,
    price: 15000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, зеленый',
    seriesId: 10
  },
  {
    id: 102,
    titleRu: 'Берег',
    titleEn: 'Coast',
    slug: 'coast',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2019,
    price: 15000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, желтый',
    seriesId: 10
  },
  {
    id: 103,
    titleRu: 'Отражение',
    titleEn: 'Reflection',
    slug: 'reflection',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2020,
    price: 9000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый',
    seriesId: 10
  },
  {
    id: 104,
    titleRu: 'Теплый вечер',
    titleEn: 'Warm Evening',
    slug: 'warm-evening',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 35,
    width: 50,
    year: 2020,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, желтый',
    seriesId: 10
  },
  {
    id: 105,
    titleRu: 'Голос предков',
    titleEn: 'Voice of the Ancestors',
    slug: 'voice-of-the-ancestors',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 50,
    year: 2020,
    price: 40000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый',
    seriesId: 7
  },
  {
    id: 106,
    titleRu: 'В ожидании',
    titleEn: 'Waiting',
    slug: 'waiting',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 45,
    width: 35,
    year: 2020,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 11
  },
  {
    id: 107,
    titleRu: 'Ангел',
    titleEn: 'Angel',
    slug: 'angel',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 120,
    width: 105,
    year: 2020,
    price: 150000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Синий, красный',
    seriesId: 11
  },
  {
    id: 108,
    titleRu: 'Факир',
    titleEn: 'Fakir',
    slug: 'fakir',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2020,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, зеленый',
    seriesId: 11
  },
  {
    id: 109,
    titleRu: 'Сила жизни',
    titleEn: 'Power of Life',
    slug: 'power-of-life',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 80,
    width: 60,
    year: 2020,
    price: 60000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Желтый, зеленый',
    seriesId: 7
  },
  {
    id: 110,
    titleRu: 'Суета сует',
    titleEn: 'Vanity of Vanities',
    slug: 'vanity-of-vanities',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 100,
    year: 2020,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, красный',
    seriesId: 11
  },
  {
    id: 111,
    titleRu: 'Рождение',
    titleEn: 'The Birth',
    slug: 'the-birth',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 50,
    year: 2020,
    price: 80000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Коричневый, краный',
    seriesId: 11
  },
  {
    id: 113,
    titleRu: 'Девичник',
    titleEn: 'Bachelorette Party',
    slug: 'bachelorette-party',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 30,
    year: 2020,
    price: 30000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, коричневый',
    seriesId: 11
  },
  {
    id: 114,
    titleRu: 'Цвет города',
    titleEn: 'City Colour',
    slug: 'city-colour',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 50,
    year: 2020,
    price: 23000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, коричневый, желтый',
    seriesId: 10
  },
  {
    id: 118,
    titleRu: 'Окно времени',
    titleEn: 'Time Window',
    slug: 'time-window',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 40,
    year: 2021,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный',
    seriesId: 7
  },
  {
    id: 119,
    titleRu: 'Солнечное видение',
    titleEn: 'Solar Vision',
    slug: 'solar-vision',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 100,
    width: 100,
    year: 2021,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Красный, оранжевый, Желтый',
    seriesId: 10
  },
  {
    id: 124,
    titleRu: 'Фантазмы подсолнухи (графика)',
    titleEn: 'Phantasms sunflowers (graphics)',
    slug: 'phantasms-sunflowers-graphics',
    materialRu: 'Бумага, карандаш',
    materialEn: 'Pencil on paper',
    height: 78,
    width: 52,
    year: 2021,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Черно-белый',
    seriesId: 9
  },
  {
    id: 127,
    titleRu: 'Домик у моря',
    titleEn: 'House by the sea',
    slug: 'house-by-the-sea',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 90,
    year: 2022,
    price: 60000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Зеленый, желтый',
    seriesId: 10
  },
  {
    id: 128,
    titleRu: 'Натюрморт с мандаринами',
    titleEn: 'Still life with tangerines',
    slug: 'still-life-with-tangerines',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 29,
    width: 49,
    year: 2012,
    price: 23000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Оранжевый, желтый, коричневый',
    seriesId: 9
  },
  {
    id: 129,
    titleRu: 'Этюд с баркасом №2',
    titleEn: 'Etude with longboat No. 2',
    slug: 'etude-with-longboat-no-2',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 24.6,
    width: 29.6,
    year: 2021,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, коричневый',
    seriesId: 10
  },
  {
    id: 130,
    titleRu: 'Этюд с баркасом №1',
    titleEn: 'Etude with longboat No. 1',
    slug: 'etude-with-longboat-no-1',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 21.5,
    width: 24.5,
    year: 2021,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'фиолетовый ,коричневый',
    seriesId: 10
  },
  {
    id: 131,
    titleRu: 'Вечерний этюд №1',
    titleEn: 'Evening sketch No. 1',
    slug: 'evening-sketch-no-1',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 24,
    width: 18,
    year: 2021,
    price: 4500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий',
    seriesId: 10
  },
  {
    id: 132,
    titleRu: 'Горы',
    titleEn: 'Mountains',
    slug: 'mountains',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 20,
    width: 24.5,
    year: 2021,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 135,
    titleRu: 'Крымский урожай',
    titleEn: 'Crimean harvest',
    slug: 'crimean-harvest',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 50,
    year: 2021,
    price: 25000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 9
  },
  {
    id: 137,
    titleRu: 'Состояние',
    titleEn: 'Condition',
    slug: 'condition',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2021,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, оранжевый',
    seriesId: 10
  },
  {
    id: 138,
    titleRu: 'Айва',
    titleEn: 'Quince',
    slug: 'quince',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2021,
    price: 17000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 140,
    titleRu: 'Бухта космонавтов №2',
    titleEn: 'Cosmonauts Bay No. 2',
    slug: 'cosmonauts-bay-no-2',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 9.7,
    width: 99.7,
    year: 2021,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'оранжевый, коричневый',
    seriesId: 10
  },
  {
    id: 143,
    titleRu: 'Бухта космонавтов №4',
    titleEn: 'Cosmonauts Bay No. 4',
    slug: 'cosmonauts-bay-no-4',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 19.6,
    width: 20.2,
    year: 2021,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой, коричневый',
    seriesId: 10
  },
  {
    id: 144,
    titleRu: 'Бухта космонавтов №5',
    titleEn: 'Cosmonauts Bay No. 5',
    slug: 'cosmonauts-bay-no-5',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 9.1,
    width: 16,
    year: 2021,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 145,
    titleRu: 'Казантип',
    titleEn: 'Kazantip',
    slug: 'kazantip',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 8,
    width: 16.4,
    year: 2021,
    price: 2500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий, желтый',
    seriesId: 10
  },
  {
    id: 146,
    titleRu: 'Дом на берегу',
    titleEn: 'House on the shore',
    slug: 'house-on-the-shore',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 10,
    width: 15.2,
    year: 2021,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой, желтый',
    seriesId: 10
  },
  {
    id: 150,
    titleRu: 'Игра света',
    titleEn: 'The play of light',
    slug: 'the-play-of-light',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 10,
    width: 14.8,
    year: 2021,
    price: 2500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 151,
    titleRu: 'Весна в Крыму',
    titleEn: 'Spring in Crimea',
    slug: 'spring-in-crimea',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 20,
    year: 2022,
    price: 8000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'розовый, фиолетовый',
    seriesId: 10
  },
  {
    id: 152,
    titleRu: 'Цветущий Крым',
    titleEn: 'Blooming Crimea',
    slug: 'blooming-crimea',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 35,
    year: 2022,
    price: 5500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'Фиолетовый, зеленый',
    seriesId: 10
  },
  {
    id: 153,
    titleRu: 'Сирень',
    titleEn: 'Lilac bush',
    slug: 'lilac-bush',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 30,
    year: 2022,
    price: 9000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'розовый, зеленый',
    seriesId: 10
  },
  {
    id: 154,
    titleRu: 'Кружево природы',
    titleEn: 'Lace of Nature',
    slug: 'lace-of-nature',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 14,
    width: 23,
    year: 2022,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой, коричневый',
    seriesId: 10
  },
  {
    id: 155,
    titleRu: 'Портрет сирени',
    titleEn: 'Portrait of lilac',
    slug: 'portrait-of-lilac',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2022,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'розовый, зеленый',
    seriesId: 10
  },
  {
    id: 156,
    titleRu: 'Крым. Мысовое',
    titleEn: 'Crimea. Mysovoye',
    slug: 'crimea-mysovoye',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 29,
    width: 14,
    year: 2022,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой, синий',
    seriesId: 10
  },
  {
    id: 157,
    titleRu: 'Домик',
    titleEn: 'Lodge',
    slug: 'lodge',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 9.5,
    width: 12,
    year: 2022,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'розовый, зеленый',
    seriesId: 10
  },
  {
    id: 158,
    titleRu: 'Теплый вечер',
    titleEn: 'Warm evening',
    slug: 'warm-evening',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 10,
    width: 12,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый',
    seriesId: 10
  },
  {
    id: 159,
    titleRu: 'Пейзаж',
    titleEn: 'Landscape',
    slug: 'landscape',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 9.5,
    width: 12,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'белый, коричневый',
    seriesId: 10
  },
  {
    id: 160,
    titleRu: 'За 2 дня до лета',
    titleEn: '2 days before summer',
    slug: '2-days-before-summer',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 9,
    width: 15.5,
    year: 2022,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый',
    seriesId: 10
  },
  {
    id: 161,
    titleRu: 'Крым в цвету',
    titleEn: 'Crimea in bloom',
    slug: 'crimea-in-bloom',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 15,
    width: 26,
    year: 2022,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, фиолетовый',
    seriesId: 10
  },
  {
    id: 162,
    titleRu: 'Закат',
    titleEn: 'Sunset',
    slug: 'sunset',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 26,
    width: 18,
    year: 2022,
    price: null,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 164,
    titleRu: 'Домик у моря',
    titleEn: 'House by the sea',
    slug: 'house-by-the-sea',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 14,
    width: 21,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'фиолетовый',
    seriesId: 10
  },
  {
    id: 165,
    titleRu: 'День',
    titleEn: 'Day',
    slug: 'day',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 12,
    width: 9.5,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой, зеленый',
    seriesId: 10
  },
  {
    id: 166,
    titleRu: 'Весенний этюд',
    titleEn: 'Spring sketch',
    slug: 'spring-sketch',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: null,
    width: null,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, голубой',
    seriesId: 10
  },
  {
    id: 167,
    titleRu: 'Этюд куста сирени',
    titleEn: 'A study of a lilac bush',
    slug: 'a-study-of-a-lilac-bush',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 20,
    width: 20,
    year: 2022,
    price: 3500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'фиолетовый, голубой',
    seriesId: 10
  },
  {
    id: 169,
    titleRu: 'Маки перед Владимирским храмом',
    titleEn: 'Poppies in front of the Vladimir Church',
    slug: 'poppies-in-front-of-the-vladimir-church',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2022,
    price: 30000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, красный',
    seriesId: 10
  },
  {
    id: 170,
    titleRu: 'Оборонное',
    titleEn: 'Defense',
    slug: 'defense',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 55,
    width: 75,
    year: 2022,
    price: 35000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, оранжевый',
    seriesId: 10
  },
  {
    id: 171,
    titleRu: 'Мысовое',
    titleEn: 'Cape',
    slug: 'cape',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 19,
    width: 19,
    year: 2022,
    price: 10000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 172,
    titleRu: 'Дебаркадер',
    titleEn: 'Landing stage',
    slug: 'landing-stage',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 10,
    width: 20,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий, зеленый',
    seriesId: 10
  },
  {
    id: 173,
    titleRu: 'Орлинное',
    titleEn: 'Eagleraven',
    slug: 'eagleraven',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 60,
    year: 2022,
    price: 30000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий, белый, зеленый',
    seriesId: 10
  },
  {
    id: 174,
    titleRu: 'Вечерний',
    titleEn: 'Evening',
    slug: 'evening',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 14,
    width: 29,
    year: 2022,
    price: 8000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой, зеленый',
    seriesId: 10
  },
  {
    id: 176,
    titleRu: 'Вечность',
    titleEn: 'Eternity',
    slug: 'eternity',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 70,
    width: 80,
    year: 2022,
    price: 100000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой',
    seriesId: 10
  },
  {
    id: 182,
    titleRu: 'Успенский собор',
    titleEn: 'Uspensky cathedral',
    slug: 'uspensky-cathedral',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 40,
    year: 2022,
    price: 40000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий, белый, желтый',
    seriesId: 10
  },
  {
    id: 183,
    titleRu: 'Вечер',
    titleEn: 'Evening',
    slug: 'evening',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 20,
    width: 18,
    year: 2022,
    price: 10000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий',
    seriesId: 10
  },
  {
    id: 184,
    titleRu: 'Свет',
    titleEn: 'Light',
    slug: 'light',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 24,
    width: 11,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'желтый, зеленый, голубой',
    seriesId: 10
  },
  {
    id: 185,
    titleRu: 'Свет 2',
    titleEn: 'Light 2',
    slug: 'light-2',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 10,
    width: 10,
    year: 2022,
    price: 1000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'зеленый, голубой',
    seriesId: 10
  },
  {
    id: 186,
    titleRu: 'Успенский собор Ростов Велкий',
    titleEn: 'Uspensky cathedral Rostov the Great',
    slug: 'uspensky-cathedral-rostov-the-great',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 39,
    width: 19,
    year: 2022,
    price: 7000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'голубой',
    seriesId: 10
  },
  {
    id: 187,
    titleRu: 'Берег Казантипа',
    titleEn: 'The coast of Kazantip',
    slug: 'the-coast-of-kazantip',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 20,
    width: 50,
    year: 2022,
    price: 27000,
    authorRu: 'Даниил Волков',
    authorEn: 'Daniil Volkov',
    color: 'голубой',
    seriesId: 10
  },
  {
    id: 191,
    titleRu: 'Небо. Ветер',
    titleEn: 'Sky. Wind',
    slug: 'sky-wind',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 14,
    width: 19,
    year: 2022,
    price: 10000,
    authorRu: 'Даниил Волков',
    authorEn: 'Daniil Volkov',
    color: '',
    seriesId: 10
  },
  {
    id: 194,
    titleRu: 'Осень в России',
    titleEn: 'Autumn in Russia',
    slug: 'autumn-in-russia',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 19,
    width: 20,
    year: 2022,
    price: 5000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий, белый, оранжевый',
    seriesId: 10
  },
  {
    id: 195,
    titleRu: 'Сергиев Посад',
    titleEn: 'Sergiev Posad',
    slug: 'sergiev-posad',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 13,
    width: 25,
    year: 2022,
    price: 4500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'синий, зеленый',
    seriesId: 10
  },
  {
    id: 196,
    titleRu: 'Байда',
    titleEn: 'Bayda',
    slug: 'bayda',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 40,
    year: 2022,
    price: 30000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'коричнеый, оранжевый, зеленый',
    seriesId: 10
  },
  {
    id: 197,
    titleRu: 'Вдали',
    titleEn: 'Far away',
    slug: 'far-away',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 55,
    width: 40,
    year: 2022,
    price: 30000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: 'коричневый ,зеленый',
    seriesId: 10
  },
  {
    id: 198,
    titleRu: 'Домик у моря',
    titleEn: 'House by the sea',
    slug: 'house-by-the-sea',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 90,
    year: 2022,
    price: 60000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 203,
    titleRu: 'Калиточка',
    titleEn: 'The gate',
    slug: 'the-gate',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 9,
    width: 19,
    year: 2022,
    price: 3500,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 205,
    titleRu: 'Воскресный день',
    titleEn: 'Sunday afternoon',
    slug: 'sunday-afternoon',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 16,
    width: 22,
    year: 2022,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 207,
    titleRu: 'Отражение',
    titleEn: 'Reflection',
    slug: 'reflection',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 30,
    width: 15,
    year: 2022,
    price: 9000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 211,
    titleRu: '7:00',
    titleEn: '7:00',
    slug: '7-00',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 30,
    width: 20,
    year: 2022,
    price: 8000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 222,
    titleRu: 'Момент',
    titleEn: 'Moment',
    slug: 'moment',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 15,
    width: 12,
    year: 2022,
    price: 3000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 224,
    titleRu: 'Деревенька',
    titleEn: 'Little village',
    slug: 'little-village',
    materialRu: 'Картон, масло',
    materialEn: 'Oil on cardboard',
    height: 10,
    width: 20,
    year: 2022,
    price: 4000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 228,
    titleRu: 'Свидание',
    titleEn: 'Date',
    slug: 'date',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 24,
    width: 30,
    year: 2014,
    price: 15000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 235,
    titleRu: 'Белые розы',
    titleEn: 'White roses',
    slug: 'white-roses',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 40,
    width: 50,
    year: 2023,
    price: 15000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 236,
    titleRu: 'Сад',
    titleEn: 'Garden',
    slug: 'garden',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 70,
    year: 2023,
    price: 20000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 237,
    titleRu: 'Альбинос',
    titleEn: 'Albino',
    slug: 'albino',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 40,
    year: 2023,
    price: 15000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 9
  },
  {
    id: 238,
    titleRu: 'Цветущий дворик',
    titleEn: 'Blooming courtyard',
    slug: 'blooming-courtyard',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 50,
    year: 2023,
    price: 22000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 239,
    titleRu: 'Яхт-клуб',
    titleEn: 'Yacht Club',
    slug: 'yacht-club',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 30,
    width: 40,
    year: 2023,
    price: 18000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 241,
    titleRu: 'Контражур',
    titleEn: 'Contre-jour',
    slug: 'contre-jour',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 50,
    width: 60,
    year: 2023,
    price: 23000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 242,
    titleRu: 'Лодки',
    titleEn: 'Boats',
    slug: 'boats',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 45,
    width: 90,
    year: 2023,
    price: 25000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  },
  {
    id: 243,
    titleRu: 'На закате',
    titleEn: 'At sunset',
    slug: 'at-sunset',
    materialRu: 'Холст, масло',
    materialEn: 'Oil on canvas',
    height: 60,
    width: 70,
    year: 2023,
    price: 23000,
    authorRu: 'Юлия Кудина',
    authorEn: 'Julia Kudina',
    color: '',
    seriesId: 10
  }
].reverse();
