import { useCallback } from 'react';

// Material UI
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// React Router Navigation
import { Outlet, ScrollRestoration } from 'react-router-dom';

// Localization
import { useTranslation } from 'react-i18next';

// Layout Imports
// import MainHeader from './MainHeader';
import MainLeftBar from './MainLeftBar';
import MainRightBar from './MainRightBar';
import Copyright from '../../components/Copyright';
import Header from './MainHeader';

// MUI Styling
const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%'
});

export default function MainLayout() {
  const { i18n, t } = useTranslation();

  const changeLanguage = () => {
    i18n.language === 'ru' ? i18n.changeLanguage('en') : i18n.changeLanguage('ru');
  };

  let getKey = useCallback((location, matches) => {
    let match = matches.find((m) => m.handle?.scrollMode);
    if (match?.handle?.scrollMode === 'pathname') {
      return location.pathname;
    }

    return location.key;
  }, []);

  return (
    <RootStyle>
      {/* <MainHeader changeLanguage={changeLanguage} t={t} /> */}
      <Grid
        container
        minHeight='100vh'
        justifyContent='space-between'
        columnSpacing={{ xs: 0, sm: 0, md: 0 }}
      >
        <Grid item md={9} xs={12} order={{ xs: 3 }}>
          <Grid container columnSpacing={{ xs: 0, sm: 0, md: 0 }} justifyContent='space-between'>
            <Grid item xs={12}>
              <Header t={t} />
            </Grid>

            <Grid
              item
              md={1.5}
              xs={0}
              order={{ xs: 1 }}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <MainLeftBar />
            </Grid>

            <Grid item md={10} xs={12} order={{ xs: 3 }} sx={{ paddingRight: { md: '40px' } }}>
              <Box
                sx={{
                  width: { xs: '90%', md: '95%' },
                  mx: 'auto',
                  bgcolor: 'background.paper',
                  pb: 6
                }}
              >
                <Outlet />
              </Box>
              <Copyright sx={{ display: { xs: 'block', md: 'none' } }} />
            </Grid>
          </Grid>
        </Grid>

        {/* Background unit */}
        <Grid
          item
          md={3}
          xs={12}
          sx={{ top: '0', bottom: '0', position: { md: 'sticky' } }}
          height='100%'
          backgroundColor='#f4f4f4'
          order={{ xs: 2, md: 3 }}
        >
          <MainRightBar changeLanguage={changeLanguage} t={t} />
        </Grid>
      </Grid>

      <ScrollRestoration getKey={getKey} />
    </RootStyle>
  );
}
