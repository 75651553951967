import * as React from 'react';

// Material UI
import { Link, Box, Container } from '@mui/material';

// Router Navigation
import { Link as RouterLink } from 'react-router-dom';

const Header = ({ t }) => {
  const LinkRouter = (props) => <Link component={RouterLink} {...props} />;

  return (
    <Box component='header'>
      <Container sx={{ height: '48px', paddingTop: 5 }}>
        <LinkRouter
          to={`/`}
          underline='none'
          sx={{
            textTransform: 'uppercase',
            width: 'fit-content',
            color: { xs: 'white', md: 'black' }
          }}
        >
          {t('header.title')}
        </LinkRouter>
      </Container>
    </Box>
  );
};

export default Header;
